.App {
  text-align: left;
}

html,
body {
  min-height: 100% !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  padding: 8px 12px;
  box-shadow: 1px 1px 6px #d3d4d5;
  margin-top: 12px;
  margin-bottom: 12px;
}

.date-pickers-container {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.date-pickers-component {
  display: flex;
  flex-flow: column;
  width: fit-content;
  margin-right: 24px;

  label {
    cursor: pointer;
    font-weight: 500;
  }

  input {
    border: 1px solid #cacaca;
    color: #cacaca;
    border-radius: 8px;
    padding: 4px 12px;
    cursor: pointer;
  }
}

.mocafi-logo-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  img {
    height: 72px;
  }
  margin: 12px 48px;
}

.react-datepicker-wrapper {
  width: 100%;
}

@media all and (display-mode: standalone) {
  html,
  body {
    background-color: #f2f3f3;
  }
}

.operative-system-logo-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.operative-system-logo {
  height: 70px;
  margin: 0px 36px;
  // width: 70px;
  cursor: pointer;
  transition: all 300ms;
}

.operative-system-logo:hover {
  opacity: 0.5;
  transform: scale(1.05, 1.05);
}

/* AMPLIFY AUTH FLOW */
.amplify-button,
.amplify-button-primary {
  background-color: #465b7a !important;
}

.amplify-icon {
  color: #ffffff !important;
}

.amplify-button--link {
  color: #ffffff !important;
}
