.instructions-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.install-process-step-image {
  height: 550px;
  width: 300px !important;
  margin: 36px 0px;
}
