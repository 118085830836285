@import '../../../variables/styles.scss';

.checkbox-button {
  display: flex;
  flex-flow: row;
  margin-right: 36px;
  img {
    height: 24px;
    transition: all 300ms;
    cursor: pointer;
  }
  h6 {
    margin: 0px 0px 0px 6px;
  }
}

.service-buttons-container {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.service-button {
  background-color: #e9e9e9;
  padding: 6px 24px;
  border-radius: 100px;
  width: fit-content;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 12px 24px 12px 0px;
  cursor: pointer;
  transition: all 300ms;
  h6 {
    margin-bottom: 0px;
  }
}

.service-button:hover {
  background-color: #465b7a;
  h6 {
    margin-bottom: 0px;
    color: #ffffff;
  }
}

.plan-component {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: 48px 0px;
  h1 {
    font-weight: bolder;
  }
}

.plan-classic-color {
  color: #179cff;
}

.filter-button {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: fit-content;
  padding: 8px 24px;
  position: relative;
  h5 {
    margin: 0px 0px 2px 0px;
  }
  img {
    margin-right: 6px;
    max-height: 24px;
  }
  cursor: pointer;
  transition: all 300ms;
}

.filter-button:hover {
  background-color: $link-component;
  border-radius: 8px;
}

.filter-button-active-bar {
  height: 4px;
  width: 100%;
  background-color: #465b7a;
  position: absolute;
  bottom: -3px;
  left: 0px;
  border-radius: 100px;
}
.service-filter-button-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: fit-content;
  width: 100%;
}

.filter-button-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  // margin: 12px 0px 0px 0px;
  width: fit-content;
  // width: 100%;
}

.filter-button-area {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
}

.service-filter-buttons-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: fit-content;
}

.gray-line {
  border: 1px solid #a4a4a4;
  height: 1px;
  width: 100%;
  margin-bottom: 18px;
}
.light-gray-line {
  height: 1px;
  background-color: #a4a4a4;
  width: 100%;
  margin-bottom: 12px;
}

.action-button {
  width: fit-content;
  // max-width: 45%;
  height: 100%;
  margin: 0px 12px;
  min-width: 160px;
  padding: 6px 18px 8px 18px;
  border-radius: 8px;
  background-color: $action-button-bg-color;
  border: 1px solid $action-button-bg-color;
  color: $action-text-bg-color;
  text-align: center;
  cursor: pointer;
  transition: all 300ms;
  h6 {
    margin: 0px;
  }
}

.alt-action-button {
  background-color: #f7f8f9 !important;
  border: 1px solid #a4a4a4 !important;
  color: black !important;
}

.action-button:hover {
  opacity: 0.8;
}

.table-action-button {
  border: 1px solid #a4a4a4;
  border-radius: 4px;
  margin-right: 12px;
  width: 40px;
  padding: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms;
}

.table-action-button:hover {
  background-color: rgba(164, 164, 164, 0.1);
}

.buttons-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px 0px 8px 0px;
}

.buttons-center-container {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
  margin: 0px 0px 12px 0px;
  div {
    margin-right: 24px;
  }
}

.select-service-button {
  height: 60px;
  margin-right: 12px;
  cursor: pointer;
  transition: all 300ms;
}

.select-service-button:hover {
  opacity: 0.7;
}

.copy-button-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms;
}

.copy-button-container:hover {
  opacity: 0.8;
}

.copy-button {
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 6px 12px;
  background-color: #f7f8f9;
  display: flex;
  flex-flow: row;
  align-items: center;
  img {
    margin: 0px 6px;
  }
  h6 {
    margin: 0px;
  }
}

.breadcrumbs {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  font-size: 18px;
  width: fit-content;

  span {
    font-weight: bolder;
    color: #465b7a;
    font-size: 20px;
  }

  a {
    color: black !important;
    text-decoration: none !important;
    margin: 0px 6px !important;
  }
}
