@import '../../../variables/styles.scss';

.form-input-title {
  margin: 0px 0px 12px 0px !important;
}

.input-component-container {
  display: flex;
  flex-flow: column;
  margin: 4px 0px;

  label {
    font-weight: 500;
    margin: 4px 0px;
    height: 24px;
    text-align: left;
    cursor: pointer;
  }

  textarea {
    min-height: 100px !important;
  }

  .error-input {
    border: 3px solid $input-component-error-text-color;
  }

  .checkbox-input {
    width: 18px !important;
    height: 18px;
  }

  .checkbox-input-sublabel-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    input {
      margin-right: 12px;
    }
    h6 {
      margin: 0px !important;
    }
  }

  input,
  select,
  textarea {
    width: 100%;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    background-color: $imput-bg-color;
    padding: 5px 10px;
  }
  input:hover {
    box-shadow: 0px 0px 2px gray;
  }
  *:disabled {
    background-color: #f2f2f2;
  }

  p {
    color: $input-component-error-text-color;
    font-size: 12px;
  }
  div {
    position: relative;
    // width: 100%;
    color: #2f4f4f;
    ion-icon {
      font-size: 24px;
      position: absolute;
      right: 12px;
      top: 6px;
      cursor: pointer;
    }
  }
}

.input-sublabel-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  h6 {
    width: fit-content;
    min-width: 75%;
    margin: 0px;
  }
  input {
    width: 100% !important;
  }
}

.simple-select {
  margin: 0px 6px;
  display: flex;
  flex-flow: column;
  label {
    margin-right: 12px;
  }
  select {
    border: 1px solid #cacaca;
    //   color: #cacaca;
    border-radius: 8px;
    padding: 4px 12px;
    cursor: pointer;
  }
}

.select-per-page {
  margin: 0px 6px;
  label {
    margin-right: 12px;
  }
  select {
    border: 1px solid #cacaca;
    //   color: #cacaca;
    border-radius: 8px;
    padding: 4px 12px;
    cursor: pointer;
  }
}

.incidence-search {
  display: flex;
  flex-flow: row;
  position: relative;
  .searchImageContainers {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 36px;
    border: 1px solid #cacaca;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    cursor: pointer;
  }
  input {
    background-color: #f7f8f9;
    border: 1px solid #cbcbcb;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 280px;
    padding: 0px 12px;
  }
  .incidence-search-options-container {
    position: absolute;
    top: 36px;
    left: 0px;
    width: 100%;
    border: 1px solid #cacaca;
    display: flex;
    flex-flow: column;
    background-color: #f7f8f9;
    z-index: 2;
    border-radius: 8px;
    div {
      // width: 100%;
      cursor: pointer;
      transition: all 300ms;
      padding: 6px 12px;
      border-top: 1px solid gray;
      p {
        margin: 0px;
      }
    }
    div:hover {
      opacity: 0.5;
      padding-left: 24px;
    }
  }
}
