.load-spinner-cotnainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.1);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100000;
  top: 0px;
  left: 0px;
  h6 {
    color: black;
    opacity: 1 !important;
  }
}

.table-load-spinner-container {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 1px 1px 6px #d3d4d5;
  display: flex;
  padding: 36px;
  align-items: center;
  justify-content: center;
}

.section-load-spinner-container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}
